/* Základní nastavení navigace */
.navigation {
    position: fixed;
    bottom: 10px;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    z-index: 10; /* Zajištění správného pořadí */
}

/* Hamburger tlačítko */
.hamburger {
    width: 60px;
    height: 60px;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 2rem;
    display: none; /* Skrytí na větších obrazovkách */
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 25; /* Vysoká priorita pro hamburger */
    transition: transform 0.3s ease;
    background-color: transparent;
}

.hamburger.open {
    transform: rotate(90deg); /* Volitelně animace křížku */
}

/* Fullscreen navigace po tom co se to otevře na menším zařízení*/
.fullscreen-nav {
    display: none; /* Skryto ve výchozím stavu */
    justify-content: center;
    align-items: center;
    z-index: 15;
}

.fullscreen-nav.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #120206;
    z-index: 20;
}

/* Odkazy ve fullscreen navigaci */
.nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.nav-links li {
    margin: 20px 0;
}

.nav-links a {
    text-decoration: none;
    color: white;
    font-size: 2rem;
    transition: color 0.3s ease;
}

/* Desktopová navigace */
.nav-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 15px 15px 0 0;
    height: 50px;
}

.nav-desktop li {
    display: inline-block;
}

.nav-desktop li a {
    text-decoration: none;
    color: #FDEDF1;
    font-size: 1.2rem;
    padding: 10px 20px;
    transition: all 0.4s ease;
}

.nav-desktop:hover {
    transition: all 0.4s;
    height: 62px;
}

.nav-desktop li a:hover {
    transition: all 0.4s ease;
    border-radius: 15px 15px 0 0;
    font-size: 1.5rem;
    padding: 20px 30px;
}

.nav-desktop li:first-child a:hover {
    background-color: #E22E57;
}

.nav-desktop li:nth-child(2) a:hover {
    background-color: #FFAB00;
}

.nav-desktop li:nth-child(3) a:hover {
    background-color: #009CEA;
}

.nav-desktop li:nth-child(4) a:hover {
    background-color: #74AF00;
}

/* Responzivní chování */
@media (max-width: 768px) {
    .nav-desktop {
        display: none; /* Skryjeme desktopovou navigaci na telefonech */
    }

    .hamburger {
        display: flex; /* Zobrazíme hamburger menu na telefonech */
    }
}
