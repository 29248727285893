/* Ensure html and body have 100% height */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #120206;
  display: grid;
  place-items: center;
}

/* Adjust .App to inherit 100% height from body instead of 100vh */
.App {
  
  display: grid;
  place-items: center;
}

/* Ensure .fullPage fills the entire height of its container */
.fullPage {
  min-height: 100vh; /* Full height inherited from .App */
  display: grid;
  place-items: center;
}

#top {
  width: 100%;
  background-color: #120206;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999; /* Ensure it stays above other content */
  display: flex;
  align-items: center;
  padding: 10px; /* Adjust padding as needed */
  height: 80px; /* Set a fixed height to ensure it contains the full height of the logo */
}

#logo {
  position: fixed; /* Keeps the image fixed in the viewport */
  top: 5%;         /* Adjusts vertical positioning */
  left: 2%;        /* Adjusts horizontal positioning */
  width: auto;     /* Ensure the width or size doesn't disrupt layout */
  height: auto;    /* Adjust height if needed */
  z-index: 1000;   /* Ensures it stays above other content */
}

.titleText {
  color: #FDEDF1;
  font-family: "DM Mono", monospace;
  font-weight: 300;
  font-style: normal;
  font-size: 5em;
}

.centeredText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: #efefef;
  text-align: center;
}

.footer a {
  margin: 0 20px;
  color: #120206;
}

@media (max-width: 768px) {
  .titleText {
    font-size: 4em;
  }
}

#aboutSection {
  display: flex;
  flex-direction: column; /* Vertikální uspořádání prvků */
  align-items: center; /* Horizontální zarovnání na střed */
  justify-content: flex-start; /* Zarovnání prvků nahoru */
  gap: 10px; /* Snížíme mezeru mezi prvky */
  margin-top: 20px; /* Přidáme menší odsazení od nadpisu */
}

#aboutSection h1 {
  margin: 60px;
}

.monoText {
  font-family: "DM Mono", monospace;
}

.descrText {
  font-size: large;
  text-align: center;
}